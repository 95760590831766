<template>
  <SearchFormContainer @submit.prevent="onSubmit">
    <div class="form-row">
      <div class="form-field field-is-text">
        <SearchFormFieldKeywords @change="onChange" v-model="keywords" />
      </div>
    </div>
    <template #actions>
      <button
        :disabled="isSubmitDisabled"
        class="form-action form-submit form-action-is-primary"
        type="submit"
      >
        {{ t('Search') }}
      </button>
      <button
        class="form-action form-reset form-action-is-secondary !p-0"
        :class="{ invisible: !isResetVisible }"
        @click.prevent="onReset"
      >
        {{ t('Reset') }}
      </button>
    </template>
  </SearchFormContainer>
</template>

<script setup lang="ts">
type InputType = {
  keywords: string
}

const { t } = useI18n()

const keywords = defineModel<string>('keywords', {
  default: '',
  required: true,
})

const emit = defineEmits<{
  change: [input: InputType]
  submit: [input: InputType]
  reset: []
}>()

// disable submit if form doesn't have minimum vals to submit
const isSubmitDisabled = computed(() => {
  return unref(keywords).length < 3
})

const isResetVisible = computed(() => {
  return unref(keywords).length >= 3
})

const onChange = () => {
  emit('change', { keywords: unref(keywords) })
}

const onSubmit = () => {
  emit('submit', { keywords: unref(keywords) })
}

const onReset = () => {
  emit('reset')
}
</script>
